import { Hit, SecureFieldMap, User } from "aderant-conflicts-models";

//These are columns on an entity and on the grid that should never be redacted
export const gridEntityNeverRedactColumns = ["name", "responsibleEmployee"];
//These are columns on the grid, but not on an entity that should never be redacted
const gridNeverRedactColumns = ["hitLocationsCommaSeparated", "hitStatus", "hitOwner", "searchTermsCommaSeparated", "sourceTypeDisplayValue", "previousHitStatus"];
//These are columns on the grid that should never be redacted
export const hitResultNeverRedactColumnNames = [...gridEntityNeverRedactColumns, ...gridNeverRedactColumns];
//These are columns on the Hit entity that should never be redacted - these are not visible on the grid
export const hitNeverRedactColumnNames = ["id", "hitId", "responsibleEmployeeId", "hitOwnerId", "entityType", "conflict", "entityRelationships"];
export const permanentHitWhitelistFields = [...gridEntityNeverRedactColumns, ...hitNeverRedactColumnNames];

/*
 * Defines the mapping from HitResult (definition of the viewmodel for the HitResultGrid) to the Hit (definition of a Hit as stored in the search store)
 * Only fields in the Hit.SourceData should be defined here, as only source data needs redacting
 * This is used in 2 places:
 * 1. In the client app, on the HitResultsGrid page, it is used to map a hit into a HitResult, after the hits are retrieved from the SearchStoreAPI
 * 2. In the SearchStoreAPI function app, it is used to map the whitelisted fields, which are specified as fields on the HitResult (grid viewmodel fields) to Hit fields, so we know which fields to redact on the Hit
 */
export const hitResultDataMapping: SecureFieldMap = {
    name: [
        {
            name: "fullName",
            when: (hit: Hit) => hit.sourceType == "sanctionsuk"
        },
        {
            name: "name",
            when: (hit: Hit) => true
        }
    ],
    entityId: [
        {
            name: "id",
            when: (hit: Hit) => true
        }
    ],
    matter: [
        {
            name: "nameAndCode",
            when: (hit: Hit) => hit.sourceType === "matter"
        },
        {
            name: "matter",
            when: (hit: Hit) => true
        }
    ],
    client: [
        {
            name: "nameAndCode",
            when: (hit: Hit) => hit.sourceType === "client"
        },
        {
            name: "client",
            when: (hit: Hit) => true
        }
    ],
    startDate: [
        {
            name: "startDate",
            when: (hit: Hit) => hit.sourceData.startDate !== undefined
        },
        {
            name: "openDate",
            when: (hit: Hit) => hit.sourceData.openDate !== undefined
        },
        {
            name: "dateRequested",
            when: (hit: Hit) => hit.sourceData.dateRequested !== undefined
        },
        {
            name: "dateDesignated",
            when: (hit: Hit) => hit.sourceType === "sanctionsuk"
        },
        {
            name: "entityRegulationPublicationDate",
            when: (hit: Hit) => hit.sourceType === "sanctionseu"
        },
        {
            name: "controlDate",
            when: (hit: Hit) => hit.sourceType === "sanctionsaso"
        },
        {
            name: "hireDate",
            when: (hit: Hit) => true
        }
    ],
    endDate: [
        {
            name: "endDate",
            when: (hit: Hit) => hit.sourceData.endDate !== undefined
        },
        {
            name: "closeDate",
            when: (hit: Hit) => hit.sourceData.closeDate !== undefined
        },
        {
            name: "terminateDate",
            when: (hit: Hit) => true
        }
    ],
    description: [
        {
            name: "note",
            when: (hit: Hit) => hit.sourceType === "client"
        },
        {
            name: "comments",
            when: (hit: Hit) => hit.sourceType === "clientparty" || hit.sourceType === "matterparty"
        },
        {
            name: "longName",
            when: (hit: Hit) => hit.sourceType === "matter"
        },
        {
            name: "sanctionsDescription",
            when: (hit: Hit) => hit.sourceType.startsWith("sanctions")
        },
        {
            name: "description",
            when: (hit: Hit) => true
        }
    ],
    sourceAffiliation: [
        {
            name: "type",
            when: (hit: Hit) => hit.sourceType === "clientparty" || hit.sourceType === "matterparty"
        }
    ],
    type: [
        {
            name: "type",
            when: (hit: Hit) => hit.sourceType !== "clientparty" && hit.sourceType !== "matterparty"
        }
    ],
    conflictReasonClientParty: [
        {
            name: "conflictReason",
            when: (hit: Hit) => hit.sourceType === "clientparty"
        }
    ],
    conflictReasonMatterParty: [
        {
            name: "conflictReason",
            when: (hit: Hit) => hit.sourceType === "matterparty"
        }
    ],
    conflictingParty: [
        {
            name: "conflict",
            when: (hit: Hit) => hit.sourceType === "matterparty" || hit.sourceType === "clientparty"
        }
    ],
    matterClass: [
        {
            name: "class",
            when: (hit: Hit) => hit.sourceType === "matter"
        }
    ],
    hitText: [
        {
            name: "hitText",
            when: (hit: Hit) => true
        }
    ]
};

/*
 * Given a propertyName on the HitResult (grid viewmodel), retrieves the property value from the Hit.dataSource.
 * Uses the hitResultDataMapping to derive the name of the property on the Hit.dataSource from the given propertyName on the HitResult
 * If the hitResultPropertyName does not exist in the mapping, assumes the propertyName on the HitResult equals the propertyName on the Hit
 * @param hitResultPropertyName: the name of the property on the HitResult, whose value we want to return from the hit.
 */
export function mapHitResult(hitResultPropertyName: string, hit: Hit, users?: User[]): any {
    const mapping = hitResultDataMapping[hitResultPropertyName];
    if (mapping) {
        let index = 0;
        while (index < mapping.length && !mapping[index].when(hit)) {
            index += 1;
        }
        if (index < mapping.length && mapping[index].when(hit)) {
            return hit.sourceData[mapping[index].name];
        }
        return undefined;
    }
    //Convert prior search requestedByUserId to a user name to add to the responsibleEmployee column
    if (hitResultPropertyName === "responsibleEmployee" && hit.sourceData.requestedByUserId) {
        return users?.find((user) => user.id === hit.sourceData.requestedByUserId)?.name ?? "-";
    }
    return hit.sourceData[hitResultPropertyName];
}

/*
 * Retrieves the WhiteList - an array of propertyNames on the Hit.dataSource that should not be redacted when a row is secured.
 * When firms save their whitelist to configuration, they save the HitResult propertyNames (i.e. the names of properties in the grid viewmodel)
 * This function returns the corresponding propertyNames on the Hit.dataSource (aka the entity property names), as it is used to redact fields on a Hit.
 * Uses the hitResultDataMapping to derive the name of the property on the Hit from the given propertyName on the HitResult
 * @param firmWhiteList - the whitelist for the firm as retrieved from configuration
 * @param hit - the hit to be redacted - this is required as the mapping can vary based on hit sourceData
 */
export function getWhiteList(firmWhiteList: string[], hit: Hit): string[] {
    const visibleWhenSecured: string[] = [];
    //Using the firms whitelist, we must map the grid HitResult field names back to the sourcedata field names on the Hit
    //As some mappings only apply to some hit types, the 'when' clause must be tested for the hit, when doing the mapping
    firmWhiteList.forEach((colName) => {
        const mapping = hitResultDataMapping[colName];
        if (mapping) {
            let index = 0;
            while (index < mapping.length && !mapping[index].when(hit)) {
                index += 1;
            }
            if (index < mapping.length && mapping[index].when(hit)) {
                visibleWhenSecured.push(mapping[index].name);
            }
        } else {
            visibleWhenSecured.push(colName);
        }
    });
    const whitelist = [...gridEntityNeverRedactColumns, ...hitNeverRedactColumnNames, ...visibleWhenSecured];
    return whitelist;
}
