import { APIs, MultiResponse } from "aderant-conflicts-common";
import {
    Forbidden,
    NotFound,
    Result,
    ImportDataToPost,
    GridConfiguration,
    ValidationErrors,
    EditableFieldValue,
    notFoundWithMessage,
    FirmSettings,
    IntegrationStatus,
    SanctionIntegrationStatus
} from "aderant-conflicts-models";
import { Logger } from "@aderant/aderant-web-fw-core";
import _ from "lodash";
export class AdminService {
    private readonly logger: Logger;
    private readonly adminServiceAPI: APIs.Admin.AdminProxy;

    constructor(logger: Logger, adminServiceAPI: APIs.Admin.AdminProxy) {
        this.logger = logger;
        this.adminServiceAPI = adminServiceAPI;
    }

    public async getHitResultGridConfiguration(): Promise<Result<GridConfiguration, NotFound | Forbidden>> {
        this.logger.debug("AdminService: Fetching hit results grid configuration from server");
        return await this.adminServiceAPI.customization.getHitResultGridConfiguration(undefined);
    }

    public async getApiKeys(): Promise<Result<APIs.Admin.GetKeysResponse, Forbidden>> {
        return await this.adminServiceAPI.apiKeyManagement.getKeyMetadata(undefined);
    }

    public async revokeApiKey(uniqueKeyName: string): Promise<Result<undefined, NotFound | Forbidden>> {
        return await this.adminServiceAPI.apiKeyManagement.revokeKey({ uniqueKeyName });
    }

    public async createApiKey(uniqueKeyName: string): Promise<Result<string, Forbidden | ValidationErrors | APIs.Admin.KeyNameNotUnique | APIs.Admin.KeyNameTooLong | APIs.Admin.KeyLimitReached>> {
        return await this.adminServiceAPI.apiKeyManagement.createKey({ uniqueKeyName });
    }

    public async regenerateApiKey(uniqueKeyName: string): Promise<Result<string, NotFound | Forbidden>> {
        return await this.adminServiceAPI.apiKeyManagement.regenerateKey({ uniqueKeyName });
    }

    public async fetchRlsColumnVisibility(): Promise<Result<string[], Forbidden>> {
        return await this.adminServiceAPI.firmSettings.getVisibleWhenSecureColumns(undefined);
    }

    public async saveRlsColumnVisibility(rlsVisibleFields: string[]): Promise<Result<string[], Forbidden>> {
        return await this.adminServiceAPI.firmSettings.saveVisibleWhenSecureColumns(rlsVisibleFields);
    }

    //type Clientside = { [key in Paths[number]]: { value: EditableFieldValue } | NotFound };
    public async getFirmSettingsByFieldPaths(fieldPaths: FirmSettings.FieldPath[]): Promise<Result<Record<FirmSettings.FieldPath, { value: EditableFieldValue }>, NotFound>> {
        const response = await this.adminServiceAPI.firmSettings.getFirmSettingsByFieldPaths({ fieldPaths });

        const [successfulResponses, failedResponses] = _.partition(response.body, MultiResponse.isSuccessItem);
        if (failedResponses.length > 0) {
            const notFoundPaths = failedResponses.map((r) => r.id);
            return notFoundWithMessage(`The following firm settings paths were not found: ${notFoundPaths.join(", ")}`);
        }

        return Object.fromEntries(successfulResponses.map((r) => [fieldPaths.find((p) => p === r.id), { value: r.body.value }]));
    }

    public async getFirmSettingsPageData(pageName: FirmSettings.PageDefinitionName): Promise<Result<Record<string, Record<string, any>>, NotFound>> {
        return await this.adminServiceAPI.firmSettings.getFirmSettingsPageData({ pageName });
    }

    public async saveFirmSettingsPageData(
        pageName: FirmSettings.PageDefinitionName,
        pageData: Record<string, Record<string, any>>
    ): Promise<Result<Record<string, Record<string, any>>, Forbidden | NotFound>> {
        return await this.adminServiceAPI.firmSettings.saveFirmSettingsPageData({ pageName, pageData });
    }

    public async saveSanctionsList(dataToPost: ImportDataToPost): Promise<Result<string, Forbidden | ValidationErrors>> {
        return await this.adminServiceAPI.import.saveSanctionsList({ entityName: dataToPost.entityName, dataToPost });
    }

    public async deleteEntityType(entityType: string): Promise<Result<{ entityType: string }, ValidationErrors>> {
        return await this.adminServiceAPI.delete.deleteEntityType({ entityType });
    }

    public async getIngestionPipelineStatus(): Promise<Result<IntegrationStatus[], NotFound | Forbidden | ValidationErrors>> {
        return await this.adminServiceAPI.monitor.getIngestionPipelineStatus();
    }

    public async getIngestionSanctionStatus(): Promise<Result<SanctionIntegrationStatus[], NotFound | Forbidden | ValidationErrors>> {
        return await this.adminServiceAPI.monitor.getIngestionSanctionStatus();
    }
}
