/**
 * Capitalises the first letter of each space separated word in the provided string.
 * e.g. "short name" becomes "Short Name"
 */
export function titleCase(string?: string): string {
    if (string) {
        const words = string.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
        }
        return words.join(" ");
    }
    return "";
}

/**
 * Splits a PascalCase or camelCase string to a user friendly name.
 * e.g. "shortName" and "ShortName" become "short name"
 */
export function splitToUserFriendlyString(hitLocation: string): string {
    return hitLocation.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
}

export function getSourceTypeDisplayValue(sourceType?: string): string {
    if (sourceType === "matterparty") {
        return "Matter Party";
    }
    if (sourceType === "clientparty") {
        return "Client Party";
    }
    if (sourceType === "priorsearch") {
        return "Prior Search";
    }
    if (sourceType === "sanctionsofac") {
        return "Sanctions OFAC";
    }
    if (sourceType === "sanctionsuk") {
        return "Sanctions UK";
    }
    if (sourceType === "sanctionseu") {
        return "Sanctions EU";
    }
    if (sourceType === "sanctionsaso") {
        return "Sanctions ASO";
    }
    return titleCase(sourceType);
}
