import { ImportEntityTemplate } from "./ImportEntityConfigurationTemplate";
export const DefaultImportEntityTemplate: ImportEntityTemplate[] = [
    //Once the user has done a first pass match, we should save the match to the database or blob storage (we want to audit
    //the match for each import). The next time an import is done, we should default alternateMatches from the latest audit
    //record for that entity.
    {
        entityName: "sanctionsofac",
        entityDisplayName: "Sanctions OFAC",
        columns: [
            {
                propertyLabel: "ID (ent_num)",
                propertyName: "id",
                alternateMatches: ["ent_num"],
                validations: [
                    {
                        rule: "required",
                        errorMessage: "Id is required",
                        level: "error"
                    },
                    {
                        rule: "regex",
                        value: "^[a-zA-Z0-9\\_\\-\\=]+\\s*$",
                        errorMessage: "Invalid Id",
                        level: "error"
                    }
                ],
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Name",
                propertyName: "name",
                alternateMatches: ["SDN_Name", "SDN Name"],
                validations: [
                    {
                        rule: "required",
                        errorMessage: "Name is required",
                        level: "error"
                    },
                    {
                        rule: "regex",
                        value: "[^(^\\-0\\-$)]+",
                        errorMessage: "Name can not be NULL",
                        level: "error"
                    }
                ],
                importMapping: "",
                isSearchable: true
            },
            {
                propertyLabel: "Type",
                propertyName: "type",
                alternateMatches: ["SDN_Type", "SDN Type"],
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Programs",
                propertyName: "programs",
                alternateMatches: ["Program"],
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Description",
                propertyName: "sanctionsDescription",
                alternateMatches: ["Remarks"],
                importMapping: "",
                isSearchable: false
            }
        ]
    },
    {
        entityName: "sanctionsuk",
        entityDisplayName: "Sanctions UK",
        columns: [
            {
                propertyLabel: "ID (Unique Id)",
                propertyName: "id",
                alternateMatches: ["Unique ID"],
                validations: [
                    {
                        rule: "required",
                        errorMessage: "Id is required",
                        level: "error"
                    },
                    {
                        rule: "regex",
                        value: "^[a-zA-Z0-9\\_\\-\\=]+\\s*$",
                        errorMessage: "Invalid Id",
                        level: "error"
                    }
                ],
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Name",
                propertyName: "name",
                isSearchable: true,
                derivedColumnMapping: ["name6", "name1", "name2", "name3", "name4", "name5"]
            },
            {
                propertyLabel: "Full Name", //To be used for name in grid
                propertyName: "fullName",
                isSearchable: false,
                derivedColumnMapping: ["name6", "name1"]
            },
            {
                propertyLabel: "Name 1",
                propertyName: "name1",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Name 2",
                propertyName: "name2",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Name 3",
                propertyName: "name3",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Name 4",
                propertyName: "name4",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Name 5",
                propertyName: "name5",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Name 6",
                propertyName: "name6",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Type",
                propertyName: "type",
                alternateMatches: ["Individual, Entity, Ship"],
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Subsidiaries",
                propertyName: "subsidiaries",
                importMapping: "",
                isSearchable: true
            },
            {
                propertyLabel: "Parent Company",
                propertyName: "parentCompany",
                alternateMatches: ["Parent company"],
                importMapping: "",
                isSearchable: true
            },
            {
                propertyLabel: "Business Registration Numbers",
                propertyName: "businessRegistrationNumbers",
                alternateMatches: ["Business registration number (s)"],
                importMapping: "",
                isSearchable: true
            },
            {
                propertyLabel: "Description",
                propertyName: "sanctionsDescription",
                alternateMatches: ["UK Statement of Reasons"],
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Website",
                propertyName: "website",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Regime Type",
                propertyName: "regimeType",
                alternateMatches: ["Designation source"],
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Regime Name",
                propertyName: "regimeName",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Date Designated",
                propertyName: "dateDesignated",
                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "URL",
                propertyName: "url",
                importMapping: "",
                alternateMatches: ["Other Information"],
                isSearchable: false
            }
        ]
    },
    //ToDo - US59649: This is the first pass at the EU template. It will need to be updated to match the final design.
    {
        entityName: "sanctionseu",
        entityDisplayName: "Sanctions EU",
        columns: [
            {
                propertyLabel: "ID (Entity EU Reference Number)",
                propertyName: "id",
                alternateMatches: ["Entity_EU_ReferenceNumber"],

                validations: [
                    {
                        rule: "required",
                        errorMessage: "Id is required",
                        level: "error"
                    },
                    {
                        rule: "regex",
                        value: "^[a-zA-Z0-9\\_\\-\\=\\.]+\\s*$",
                        errorMessage: "Invalid Id",
                        level: "error"
                    }
                ],
                isSearchable: false,
                importMapping: ""
            },
            {
                propertyLabel: "Name",
                propertyName: "name",
                derivedColumnMapping: ["wholeName", "firstName", "middleName", "lastName"], //Column T, R, S, Q
                isSearchable: true
            },
            {
                propertyLabel: "Whole Name",
                propertyName: "wholeName",
                alternateMatches: ["NameAlias_WholeName"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "First Name",
                propertyName: "firstName",
                alternateMatches: ["NameAlias_FirstName"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Middle Name",
                propertyName: "middleName",
                alternateMatches: ["NameAlias_MiddleName"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Last Name",
                propertyName: "lastName",
                alternateMatches: ["NameAlias_LastName"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Type",
                propertyName: "type",
                alternateMatches: ["Entity_SubjectType_ClassificationCode"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Description",
                propertyName: "sanctionsDescription",
                alternateMatches: ["NameAlias_Function"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Identification Number",
                propertyName: "identificationNumber",
                alternateMatches: ["Identification_Number"],

                importMapping: "",
                isSearchable: true
            },
            {
                propertyLabel: "Identification Latin Number",
                propertyName: "identificationLatinNumber",
                alternateMatches: ["Identification_LatinNumber"],

                importMapping: "",
                isSearchable: true
            },
            {
                propertyLabel: "Entity Regulation Programme",
                propertyName: "entityRegulationProgramme",
                alternateMatches: ["Entity_Regulation_Programme"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Entity Regulation Number Title",
                propertyName: "entityRegulationNumberTitle",
                alternateMatches: ["Entity_Regulation_NumberTitle"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "Entity Regulation Publication Date",
                propertyName: "entityRegulationPublicationDate",
                alternateMatches: ["Entity_Regulation_PublicationDate"],

                importMapping: "",
                isSearchable: false
            },
            {
                propertyLabel: "URL",
                propertyName: "url",
                alternateMatches: ["Entity_Regulation_PublicationUrl"],

                importMapping: "",
                isSearchable: false
            }
        ]
    },

    //Model Template for Sanction ASO
    {
        entityName: "sanctionsaso",
        entityDisplayName: "Sanctions ASO",
        columns: [
            {
                propertyLabel: "ID (Reference)",
                propertyName: "id",
                alternateMatches: ["Reference"],

                validations: [
                    {
                        rule: "required",
                        errorMessage: "Id is required",
                        level: "error"
                    },
                    {
                        rule: "regex",
                        value: "^[a-zA-Z0-9\\_\\-\\=]+\\s*$",
                        errorMessage: "Invalid Id",
                        level: "error"
                    }
                ],
                isSearchable: false,
                importMapping: ""
            },
            {
                propertyLabel: "Name (Name of Individual or Entity)",
                propertyName: "name",
                alternateMatches: ["Name of Individual or Entity"],
                isSearchable: true,
                importMapping: ""
            },
            {
                propertyLabel: "Type",
                propertyName: "type",
                alternateMatches: ["Type"],
                isSearchable: false,
                importMapping: ""
            },
            {
                propertyLabel: "Description",
                propertyName: "sanctionsDescription",
                alternateMatches: ["Additional Information"],
                isSearchable: false,
                importMapping: ""
            },
            {
                propertyLabel: "Committees",
                propertyName: "committees",
                alternateMatches: ["Committees"],
                isSearchable: false,
                importMapping: ""
            },
            {
                propertyLabel: "Control Date",
                propertyName: "controlDate",
                alternateMatches: ["Control Date"],
                isSearchable: false,
                importMapping: ""
            }
        ]
    }
];
