import { AllLookups, GridConfiguration, LoggedInUser, PermissionsContextDirect, User, EditableFieldValue, FirmSettings } from "aderant-conflicts-models";
import { DataGridPreference, DataGridPreferences } from "@aderant/aderant-react-components";
import { action } from "typesafe-actions";
import { ConflictsSagaContext } from "../store/ConflictsSagaContext";

export const appActionTypes = {
    LOGIN: "LOGIN",
    SHOWERROR: "SHOWERROR",
    SHOWCUSTOMERROR: "SHOWCUSTOMERROR",
    HIDEERROR: "HIDEERROR",
    SET_CONFLICTS_SAGA_CONTEXT: "SETCONFLICTSSAGACONTEXT",
    SET_LOGGED_IN_USER: "SETLOGGEDINUSER",
    FETCH_USERS: "FETCHUSERS",
    FETCH_USERS_FAILURE: "FETCHUSERSFAILURE",
    FETCH_USERS_SUCCESS: "FETCHUSERSSUCCESS",
    SET_USERS: "SETUSERS",
    FETCH_LOOKUPS: "FETCHLOOKUPS",
    FETCH_LOOKUPS_FAILURE: "FETCHLOOKUPSFAILURE",
    FETCH_LOOKUPS_SUCCESS: "FETCHLOOKUPSSUCCESS",
    FETCH_HIT_RESULT_GRID_CONFIGURATION: "FETCHHITRESULTGRIDCONFIGURATION",
    FETCH_HIT_RESULT_GRID_CONFIGURATION_FAILURE: "FETCHHITRESULTGRIDCONFIGURATIONFAILURE",
    FETCH_HIT_RESULT_GRID_CONFIGURATION_SUCCESS: "FETCHHITRESULTGRIDCONFIGURATIONSUCCESS",
    FETCH_GRID_PREFERENCES: "FETCHGRIDPREFERENCES",
    FETCH_GRID_PREFERENCES_FAILURE: "FETCHGRIDPREFERENCESFAILURE",
    FETCH_GRID_PREFERENCES_SUCCESS: "FETCHGRIDPREFERENCESSUCCESS",
    UPDATE_GRID_PREFERENCE: "UPDATEGRIDPREFERENCE",
    UPDATE_GRID_PREFERENCE_FAILURE: "UPDATEGRIDPREFERENCEFAILURE",
    UPDATE_GRID_PREFERENCE_SUCCESS: "UPDATEGRIDPREFERENCESUCCESS",
    ACTION_FAILURE: "ACTIONFAILURE",
    INITIALIZE_CONTEXT: "INITIALIZECONTEXT"
} as const;

export const appActions = {
    login: (userId: string) => action(appActionTypes.LOGIN, userId),
    showError: (message: string) => action(appActionTypes.SHOWERROR, message),
    showCustomError: (title: string, message: string) => action(appActionTypes.SHOWCUSTOMERROR, {title: title, message: message}),
    hideError: () => action(appActionTypes.HIDEERROR),
    fetchUsers: () => action(appActionTypes.FETCH_USERS),
    fetchUsersSuccess: (users: User[]) => action(appActionTypes.FETCH_USERS_SUCCESS, users),
    setUsers: (users: User[]) => action(appActionTypes.SET_USERS, users),
    fetchLookups: () => action(appActionTypes.FETCH_LOOKUPS),
    fetchLookupsSuccess: (lookups: AllLookups) => action(appActionTypes.FETCH_LOOKUPS_SUCCESS, lookups),
    fetchHitResultGridConfiguration: () => action(appActionTypes.FETCH_HIT_RESULT_GRID_CONFIGURATION),
    fetchHitResultGridConfigurationSuccess: (hitResultGridConfiguration: GridConfiguration) => action(appActionTypes.FETCH_HIT_RESULT_GRID_CONFIGURATION_SUCCESS, hitResultGridConfiguration),
    fetchGridPreferences: (subscriptionId?: string) => action(appActionTypes.FETCH_GRID_PREFERENCES, subscriptionId),
    fetchGridPreferencesSuccess: (preferences: DataGridPreferences) => action(appActionTypes.FETCH_GRID_PREFERENCES_SUCCESS, preferences),
    updateGridPreference: (payload: { preference: DataGridPreference; subscriptionId: string }) => action(appActionTypes.UPDATE_GRID_PREFERENCE, payload),
    updateGridPreferenceSuccess: (preference: DataGridPreference) => action(appActionTypes.UPDATE_GRID_PREFERENCE_SUCCESS, preference),
    actionFailure: (error: string) => action(appActionTypes.ACTION_FAILURE, error),
    initializeContext: (context: { conflictsSagaContext: ConflictsSagaContext; loggedInUser: LoggedInUser; permissionsContext: PermissionsContextDirect }) =>
        action(appActionTypes.INITIALIZE_CONTEXT, context)
};
