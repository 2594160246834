export const consolidateSanctionsData = (data, keys) => {
    const map = new Map();

    data.forEach((row: any) => {
        const uniqueKey = row[keys].trim();
        if (!map.has(uniqueKey)) {
            const newRow: any = {};
            Object.keys(row).forEach((col) => {
                if (col === "sanctionsDescription") {
                    newRow[col] = row[col] || "";
                } else if (col === "id") {
                    newRow[col] = new Set([row[col].trim()]);
                } else {
                    newRow[col] = new Set([row[col]]);
                }
            });
            map.set(uniqueKey, newRow);
        } else {
            const existingRow = map.get(uniqueKey);
            Object.keys(row).forEach((col) => {
                if (col === "sanctionsDescription") {
                    const existingDescription = existingRow[col] || "";
                    const newDescription = row[col] || "";
                    const combinedDescriptions = new Set([
                        ...existingDescription
                            .split(";")
                            .filter(Boolean)
                            .map((s: string) => s.trim()),
                        ...newDescription
                            .split(";")
                            .filter(Boolean)
                            .map((s: string) => s.trim())
                    ]);
                    existingRow[col] = Array.from(combinedDescriptions).join("; ");
                } else if (row[col]) {
                    existingRow[col]?.add(row[col]);
                }
            });
        }
    });

    return Array.from(map.values()).map((row) => {
        const resultRow: any = {};
        Object.keys(row).forEach((col) => {
            if (col === "sanctionsDescription") {
                resultRow[col] = row[col].replace(/,\s*$/, "").trim();
            } else if (col === "controlDate" || col === "entityRegulationPublicationDate" || col === "dateDesignated") {
                const dates = Array.from(row[col]);
                if (dates.length > 1) {
                    resultRow[col] = dates.join(", ").replace(/,\s,/g, ",");
                } else {
                    resultRow[col] = dates[0] + "T00:00:00";
                }
            } else {
                resultRow[col] = Array.from(row[col]).join(", ").replace(/,\s,/g, ",");
            }
        });
        return resultRow;
    });
};
export const consolidateDataUK = (data, keys) => {
    data.forEach((row: any) => {
        row.fullName = [row.name6, row.name1].filter(Boolean).join(", ");
        row.name = [row.name1, row.name2, row.name3, row.name4, row.name5, row.name6].filter(Boolean).join(";");
        if (row.dateDesignated.trim() !== "") {
            row.dateDesignated = row.dateDesignated.split("/").reverse().join("-");
        }
    });

    return consolidateSanctionsData(data, keys);
};