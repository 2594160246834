import { HitResult } from "./HitResult";
import { SearchVersion, Hit, RequestTerm, getHitStatusDisplayValue, GridConfiguration, GridColumnConfiguration, QuickSearch, User } from "aderant-conflicts-models";
import { RowLevelSecurity } from "aderant-conflicts-common";
import _ from "lodash";
import { splitToUserFriendlyString, getSourceTypeDisplayValue, titleCase } from "Functions/strings";
import { getUserName } from "state/selectors/appSelectors";

const getDescriptionFieldName = (hit: Hit) => {
    switch (hit.sourceType) {
        case "client":
            return "note";
        case "clientparty":
        case "matterparty":
            return "comments";
        case "matter":
            return "longName";
        case "sanctionsofac":
        case "sanctionseu":
        case "sanctionsuk":
        case "sanctionsaso":
            return "sanctionsDescription";
    }
    return "description";
};

const getHitOwner = (users: User[], hit: Hit) => {
    return { name: users.length > 0 || !hit.hitOwnerId ? getUserName(hit.hitOwnerId ?? undefined, users) : "Retrieving user", id: hit.hitOwnerId ?? undefined };
};

const ResultDataMapping = (searchVersion: SearchVersion | QuickSearch | undefined, gridConfiguration: GridConfiguration, users: User[]): HitResult[] => {
    const results: RequestTerm[] = searchVersion?.requestTerms || [];
    const hitsList: HitResult[] = [];
    console.debug("%s: Mapping grid with %s", new Date().toISOString(), gridConfiguration ? "a gridConfiguration value" : "undefined");

    results.forEach((requestTerm: RequestTerm) => {
        const hits = requestTerm.hits;
        if (!hits) {
            return;
        }
        hits.forEach((hit: Hit) => {
            const descriptionFieldName = getDescriptionFieldName(hit);
            const newResult: HitResult = {
                version: searchVersion?.version ?? 0,
                name: RowLevelSecurity.mapHitResult("name", hit),
                entityId: RowLevelSecurity.mapHitResult("entityId", hit),
                id: hit.id,
                hit: hit,
                hitLocationsCommaSeparated: _.uniq(hit.hitLocations?.map((l) => titleCase(splitToUserFriendlyString(l.location))))
                    .sort()
                    .join(", "),
                searchTermsCommaSeparated: _.uniq(hit.hitLocations?.map((l) => l.term))
                    .sort()
                    .join(", "),
                hitStatus: hit.status,
                hitStatusDisplayValue: getHitStatusDisplayValue(hit.status),
                hitOwner: getHitOwner(users, hit),
                hitOwnerDisplayValue: getHitOwner(users, hit).name ?? null,
                previousVersionStatusDisplayValue: hit.previousVersionStatus ? getHitStatusDisplayValue(hit.previousVersionStatus) : "",
                previousVersionStatus: hit.previousVersionStatus ? hit.previousVersionStatus : undefined,
                requestTerm: requestTerm.term,
                requestTermId: requestTerm.id,
                sourceType: hit.sourceType,
                sourceTypeDisplayValue: getSourceTypeDisplayValue(hit.sourceType),
                hitId: { requestTermId: requestTerm.id || "", hitId: hit.id },
                matter: RowLevelSecurity.mapHitResult("matter", hit),
                client: RowLevelSecurity.mapHitResult("client", hit),
                startDate: RowLevelSecurity.mapHitResult("startDate", hit),
                endDate: RowLevelSecurity.mapHitResult("endDate", hit),
                description: RowLevelSecurity.mapHitResult("description", hit),
                descriptionFieldName: descriptionFieldName,
                affiliation: requestTerm.affiliation ?? "",
                sourceAffiliation: RowLevelSecurity.mapHitResult("sourceAffiliation", hit),
                searchRequestPartyStatus: requestTerm.partyStatus ?? "",
                type: RowLevelSecurity.mapHitResult("type", hit),
                conflictReasonClientParty: RowLevelSecurity.mapHitResult("conflictReasonClientParty", hit),
                conflictingParty: RowLevelSecurity.mapHitResult("conflictingParty", hit),
                conflictReasonMatterParty: RowLevelSecurity.mapHitResult("conflictReasonMatterParty", hit),
                matterClass: RowLevelSecurity.mapHitResult("matterClass", hit),
                hitText: RowLevelSecurity.mapHitResult("hitText", hit),
                isRedacted: hit.isRedacted ?? false
            };
            gridConfiguration.configurations.forEach((column: GridColumnConfiguration) => {
                if (
                    column.propertyName !== "type" && //type is mapped above and if undefined, should stay undefined
                    newResult[column.propertyName] === undefined
                ) {
                    newResult[column.propertyName] = RowLevelSecurity.mapHitResult(column.propertyName, hit, users);
                }
            });
            hitsList.push(newResult);
        });
    });

    return hitsList;
};

export default ResultDataMapping;
