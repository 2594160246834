import { CircularProgress } from "@mui/material";
import { DurableHitIdentifier, HitStatus, QuickSearch, SearchMessages, SearchVersion } from "aderant-conflicts-models";
import { AderantTheme, Flyout, Tab, Tabs } from "@aderant/aderant-react-components";
import { HitInformationTileProps } from "components/Tile/HitInformationTile";
import { Messages } from "components/Tile/Tiles.Messages";
import { getSourceTypeDisplayValue } from "Functions/strings";
import { RootState } from "MyTypes";
import { HitResult } from "pages/ResultsPage/HitResult";
import React, { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsFetchingFlyoutEntityDetail, getCurrentUser, getCurrentHitCommentCount } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import { HitInformationTile, SecondaryInformationTiles } from "../Tile";
import { UserChangePermissions } from "pages/ResultsPage/ResultsPage";
import { RequestTermsDialog } from "pages/ResultsPage/RequestTermsDialog";
import { FlyoutMessages } from "./Messages";
import { CommentsContainer } from "components/HitComments/CommentsContainer";
import { CommentCountIcon } from "components/Icons/CommentCountIcon";
import _ from "lodash";

const tabAccessibilityProps = (tab: string): Record<string, string> => {
    return {
        id: `hit-flyout-tab-${tab.toLowerCase()}`,
        "aria-controls": `hit-flyout-tabpanel-${tab.toLowerCase()}`,
        value: tab
    };
};

const tabPanelAccessibilityProps = (tab: string): Record<string, string> => {
    return {
        role: "tabpanel",
        id: `hit-flyout-tabpanel-${tab.toLowerCase()}`,
        "aria-labelledby": `hit-flyout-tab-${tab.toLowerCase()}`
    };
};

const flyoutStyle: CSSProperties = {
    backgroundColor: conflictsPalette.background.white,
    width: "400px",
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden"
};
const headingContainer: CSSProperties = {
    padding: "15px",
    borderBottom: `1px solid ${conflictsPalette.border}`
};
const flyoutHeading: CSSProperties = {
    fontWeight: 300,
    textAlign: "center",
    fontSize: AderantTheme.typography.pxToRem(19),
    margin: "0 0 5px 0"
};
const flyoutSubheading: CSSProperties = {
    textAlign: "center",
    fontSize: AderantTheme.typography.pxToRem(15),
    margin: 0
};

export type HitDetailFlyoutProps = {
    open: boolean;
    onClickAway: (e: MouseEvent | TouchEvent) => void;
    toggleRequestTermsDialog: () => void;
    requestTermsDialogOpen: boolean;
    onClose: () => void;
    data?: HitResult;
    entityId?: string;
    userChangePermissions: UserChangePermissions;
} & HitDetailFlyoutPropsBySearchType;

type HitDetailFlyoutPropsBySearchType =
    | {
          searchVersion?: QuickSearch;
          onHitChange?: never;
          searchReadOnly?: true;
          displayTabs?: false;
      }
    | {
          searchVersion?: SearchVersion;
          onHitChange?: (status?: HitStatus, hitOwnerId?: string | null) => void;
          searchReadOnly?: boolean;
          displayTabs?: true;
      };

const getDurableHitIdentifier = (data: HitResult | undefined): DurableHitIdentifier | undefined => {
    return data
        ? {
              requestTermId: data.hitId.requestTermId ?? "",
              hitEntityId: data.hit.sourceData.id ?? "",
              hitEntityType: data.hit.sourceType ?? ""
          }
        : undefined;
};

const HitDetailFlyout = (props: HitDetailFlyoutProps): JSX.Element => {
    const dispatch = useDispatch();
    const { open, onClose, data, onClickAway, toggleRequestTermsDialog, requestTermsDialogOpen, entityId, searchVersion, displayTabs } = props;

    const currentUser = useSelector(getCurrentUser);

    const [selectedTab, setSelectedTab] = useState(FlyoutMessages.OVERVIEW.getMessage());

    const commentCount: number = useSelector((state: RootState) => getCurrentHitCommentCount(state, getDurableHitIdentifier(data)));
    const [commentIcon, setCommentIcon] = useState(CommentCountIcon({ count: commentCount, isSelected: _.isEqual(selectedTab, FlyoutMessages.COMMENTS.getMessage()) }));
    useEffect(() => {
        setCommentIcon(CommentCountIcon({ count: commentCount, isSelected: _.isEqual(selectedTab, FlyoutMessages.COMMENTS.getMessage()) }));
    }, [commentCount, selectedTab]);

    // name is always a string, but HitResult interface has key with multiple types including Date, which can't be assigned to type 'ReactNode'
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const hitResultName = data?.name as string;
    const secondaryTileData = useSelector((state: RootState) => {
        return state.search.hitFlyoutDetail;
    });
    const isFetchingEntity = useSelector((rootState: RootState) => (entityId ? getIsFetchingFlyoutEntityDetail(rootState, entityId) : false));
    const hitInformationTileProps: Partial<HitInformationTileProps> = searchVersion?.isQuickSearch
        ? {
              searchVersion: searchVersion,
              searchReadOnly: true
          }
        : {
              searchVersion: searchVersion,
              searchReadOnly: props.searchReadOnly,
              onHitChange: props.onHitChange
          };

    const handleTabChange = (newValue: string): void => {
        setSelectedTab(newValue);
    };

    return (
        <div>
            <Flyout open={open} onClose={onClose} closeButton onClickAway={onClickAway}>
                <div style={{ ...flyoutStyle, backgroundColor: conflictsPalette.background.hoverLight }}>
                    <div style={{ ...headingContainer, backgroundColor: conflictsPalette.background.white }}>
                        <h2 data-testid={"flyout-title"} style={flyoutHeading}>
                            {hitResultName}
                        </h2>
                        <p style={flyoutSubheading}>
                            <span style={{ color: conflictsPalette.text.disabled }}>{`${SearchMessages.HIT_TYPE.getMessage()}: `}</span>
                            {getSourceTypeDisplayValue(data?.hit.sourceType)}
                        </p>
                    </div>

                    {displayTabs && (
                        <div style={{ backgroundColor: conflictsPalette.background.white, marginTop: "15px", borderBottom: `1px solid ${conflictsPalette.primary.Ahsoka}` }}>
                            <Tabs value={selectedTab} onChange={(e, v) => handleTabChange(v)} centered aria-label={FlyoutMessages.HIT_FLYOUT_ARIA_LABEL.getMessage()}>
                                <Tab label={FlyoutMessages.OVERVIEW.getMessage()} {...tabAccessibilityProps("Overview")} />
                                <Tab label={FlyoutMessages.COMMENTS.getMessage()} {...tabAccessibilityProps("Comments")} icon={commentIcon} iconPosition="end" />
                            </Tabs>
                        </div>
                    )}

                    {selectedTab === FlyoutMessages.OVERVIEW.getMessage() && (
                        <div {...tabPanelAccessibilityProps("Overview")} style={{ padding: "15px", flex: "1 1 auto", overflowY: "auto" }}>
                            <HitInformationTile data={props.data} userChangePermissions={props.userChangePermissions} {...hitInformationTileProps} />
                            {data?.isRedacted ? (
                                <div></div>
                            ) : isFetchingEntity ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <CircularProgress variant="indeterminate" color="primary" size="2rem" title={Messages.LOADING_DATA_PLEASE_WAIT_TITLE.getMessage()} />
                                </div> //ToDo: Confirm with UX
                            ) : secondaryTileData.entity && secondaryTileData?.entity?.fields?.length > 0 ? (
                                <SecondaryInformationTiles entityDetails={secondaryTileData.entity} toggleRequestTermsDialog={toggleRequestTermsDialog} />
                            ) : (
                                <div role="status" style={{ fontSize: "1rem", textAlign: "center" }}>
                                    {Messages.NO_DATA_AVAILABLE_MESSAGE.getMessage()}
                                </div>
                            )}
                        </div>
                    )}
                    {selectedTab === FlyoutMessages.COMMENTS.getMessage() && (
                        <div style={{ backgroundColor: conflictsPalette.background.hoverLight, padding: "15px", flex: "1 1 auto", overflowY: "auto" }}>
                            {currentUser && (
                                <CommentsContainer
                                    currentUser={currentUser}
                                    durableHitIdentifier={{ hitEntityId: data?.hit.sourceData.id ?? "", hitEntityType: data?.hit.sourceType ?? "", requestTermId: data?.hitId.requestTermId ?? "" }}
                                    searchVersionIdentifier={{ searchId: searchVersion?.searchId ?? "", versionId: searchVersion?.id ?? "" }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </Flyout>
            <RequestTermsDialog open={requestTermsDialogOpen} requestTerms={secondaryTileData.entity?.searchRequestTerms ?? []} onClose={toggleRequestTermsDialog} />
        </div>
    );
};

export default HitDetailFlyout;
