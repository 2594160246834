import { ConnectionContext, CurrentUserContext, PermissionsContext, unexpectedError } from "aderant-conflicts-models";
import { TableStore, TableClientOptions } from "@aderant/azure-storage";
import { Context, Logger } from "@azure/functions";
import { FunctionAppContext } from "../ConflictsContext";
import { TableEntity } from "@azure/data-tables";
import { get } from "lodash";

export type IngestionStatusConnectorContext = FunctionAppContext | CurrentUserContext | ConnectionContext | PermissionsContext | Context | Logger;

export interface IngestionStatusEntity {
    partitionKey: string;
    rowKey: string;
    status: "Failed" | "Completed" | "Pending" | "NotImported";
    lastStatusChangeTime: Date;
    lastSuccessTime: Date | null;
}

export class IngestionStatusConnector {
    private tableStore: Pick<TableStore, "get" | "upsertEntity" | "deleteEntities" | "delete" | "list">;
    private context: IngestionStatusConnectorContext;

    private constructor(context: IngestionStatusConnectorContext, tableStoreInput: Pick<TableStore, "get" | "upsertEntity" | "deleteEntities" | "delete" | "list">) {
        this.context = context;
        this.tableStore = tableStoreInput;
    }

    public static async open(
        context: IngestionStatusConnectorContext,
        tableStoreInput?: Pick<TableStore, "get" | "upsertEntity" | "deleteEntities" | "delete" | "list">
    ): Promise<IngestionStatusConnector> {
        const tableStore = tableStoreInput ?? (await IngestionStatusConnector.getTableStorageClient(context));
        return new IngestionStatusConnector(context, tableStore);
    }

    public async getSanctionIngestionStatus(): Promise<TableEntity[] | null> {
        try {
            const entities = await this.tableStore.list();
            if (entities.length === 0) {
                return null;
            }
            return entities;
        } catch (e: any) {
            if (e.statusCode === 404) {
                return null;
            } else {
                throw unexpectedError(e, "getSanctionIngestionStatus");
            }
        }
    }

    public async getIngestionStatus(entityName: string): Promise<IngestionStatusEntity | null> {
        try {
            const entity = await this.tableStore.get(entityName, "1");
            return entity;
        } catch (e: any) {
            if (e.statusCode === 404) {
                return null;
            } else {
                throw unexpectedError(e, "getIngestionStatus");
            }
        }
    }

    public async upsertIngestionStatus(entity: IngestionStatusEntity): Promise<void> {
        try {
            await this.tableStore.upsertEntity(entity);
        } catch (e: any) {
            throw unexpectedError(e, "upsertIngestionStatus");
        }
    }

    public async deleteIngestionStatus(entityName: string): Promise<void> {
        try {
            await this.tableStore.delete({ partitionKey: entityName, rowKey: "1" });
        } catch (e: any) {
            throw unexpectedError(e, "deleteIngestionStatus");
        }
    }

    public async setPending(entityName: string): Promise<void> {
        const entity: IngestionStatusEntity = {
            partitionKey: entityName,
            rowKey: "1",
            status: "Pending",
            lastStatusChangeTime: new Date(),
            lastSuccessTime: null
        };
        await this.upsertIngestionStatus(entity);
    }

    public async setFailed(entityName: string): Promise<void> {
        const entity: IngestionStatusEntity = {
            partitionKey: entityName,
            rowKey: "1",
            status: "Failed",
            lastStatusChangeTime: new Date(),
            lastSuccessTime: null
        };
        await this.upsertIngestionStatus(entity);
    }

    public async setCompleted(entityName: string): Promise<void> {
        const entity: IngestionStatusEntity = {
            partitionKey: entityName,
            rowKey: "1",
            status: "Completed",
            lastStatusChangeTime: new Date(),
            lastSuccessTime: new Date()
        };
        await this.upsertIngestionStatus(entity);
    }

    private static async getTableStorageClient(context: IngestionStatusConnectorContext): Promise<TableStore> {
        let accountName: string;

        if ("getBlobStorageSecrets" in context) {
            const connectionInfo = await context.getBlobStorageSecrets();
            const connectionString = connectionInfo.connectionString;
            context.logger.info(`connectionInfooo: ${connectionInfo.connectionString}`);
            accountName = this.getAccountNameFromConnectionString(connectionString);
            if (context.logger && typeof context.logger.info === "function") {
                context.logger.info(`Account name: ${accountName}`);
            }
        } else {
            throw new Error("Unsupported context type");
        }
        const clientOptions: TableClientOptions = {
            accountName: accountName,
            tableName: "IngestionSanctionStatus",
            useIdentity: true
        };
        return new TableStore(clientOptions);
    }

    private static getAccountNameFromConnectionString(connectionString: string): string {
        try {
            const connectionStringProperties = connectionString
                .split(";")
                .map((propString: string) => {
                    const keyValuePair = propString.split("=");
                    if (keyValuePair.length === 0) {
                        throw new Error("Invalid connection string format");
                    }
                    const key = keyValuePair[0];
                    const value = keyValuePair.length === 2 ? keyValuePair[1] : "";
                    return { key: key, value: value };
                })
                .reduce((acc: { [key: string]: string }, prop) => {
                    acc[prop.key] = prop.value;
                    return acc;
                }, {});
            if (!connectionStringProperties.AccountName) {
                throw new Error("Account name not found in connection string");
            }
            return connectionStringProperties.AccountName;
        } catch (e: any) {
            throw new Error(`Error parsing connection string: ${e.message}`);
        }
    }
}
