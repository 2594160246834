import { Logger } from "@aderant/aderant-web-fw-core";
import { BlobStorageConnector } from "../BlobStorageConnector/BlobStorageConnector";
import { ok, unexpectedError } from "aderant-conflicts-models";

/**
 * Retrieve an avro schema for a given entity
 */
export interface AvroConnector {
    //take the schema, data and create a new avro file in the blob storage
    createAvroBlobFile(containerName: string, path: string, data: Buffer): Promise<boolean>;
}

export class AvroBlobConnector implements AvroConnector {
    private blobConnector: BlobStorageConnector;
    private logger: any;

    public constructor(connector: BlobStorageConnector, logger: Logger) {
        this.blobConnector = connector;
        this.logger = logger;
    }

    public async createAvroBlobFile(containerName: string, path: string, data: Buffer): Promise<boolean> {
        this.logger.info(`Starting to store Avro blob file in container '${containerName}' at path '${path}'`);
        try {
            this.logger.info("Uploading Avro buffer to blob storage");
            const uploadResponse = await this.blobConnector.uploadDataAsBuffer(containerName, path, data);

            if (!ok(uploadResponse)) {
                this.logger.error("Error uploading Avro blob file");
                throw unexpectedError(uploadResponse, `Error uploading avro blob file in BlobAvroConnector.createAvroBlobFile`);
            }
            this.logger.info("Successfully created Avro blob file and uploaded to blob storage");
            return true;
        } catch (error) {
            this.logger.error("Error uploading Avro blob file", error);
            return false;
        }
    }
}
