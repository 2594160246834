import { get } from "@aderant/azure-key-vault-secrets";
import {
    BlobStorageSecrets,
    CognitiveSearchSecrets,
    ConflictsSecretType,
    CosmosSecrets,
    DataFactorySecrets,
    Endpoint,
    RLSStorageConnectionInfo,
    ServiceBusSecrets,
    TableStorageSecrets,
    unexpectedError
} from "aderant-conflicts-models";
import { Logger } from "@aderant/aderant-web-fw-core";
import { EnvironmentService } from "../../..";

export function getKeyVaultName() {
    return process.env.CONFLICTS_KEYVAULTNAME ?? "";
}

export async function getSecretValueAsJSON(secretType: ConflictsSecretType, tenancyName: string, logger: Logger): Promise<unknown> {
    logger.debug(`getSecretValueAsJSON - secretType: ${secretType}, tenancyName: ${tenancyName}`);

    const secretName = `${tenancyName}-${secretType}-connectionDetails`;
    const keyVaultName = getKeyVaultName();

    logger.debug(`getSecretValueAsJSON - keyVaultName: ${keyVaultName}, secretName: ${secretName}`);

    const secretString: string = await get(keyVaultName, secretName);
    if (!secretString || secretString === "") {
        throw "Value of secret object is null";
    }

    return JSON.parse(secretString);
}

export async function getDataFactorySecrets(tenancyName: string, logger: Logger): Promise<DataFactorySecrets> {
    // justification: parsing json blob (validation function would be nice, but this will fail fast anyway as the secret wont work if it's missing values)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (await getSecretValueAsJSON("dataFactory", tenancyName, logger)) as DataFactorySecrets;
}

export async function getBlobStorageSecrets(tenancyName: string, logger: Logger): Promise<BlobStorageSecrets> {
    // justification: parsing json blob (validation function would be nice, but this will fail fast anyway as the secret wont work if it's missing values)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (await getSecretValueAsJSON("blobStorage", tenancyName, logger)) as BlobStorageSecrets;
}

export async function getRLSStorageConnectionInfo(tenancyName: string, logger: Logger): Promise<RLSStorageConnectionInfo> {
    // justification: parsing json blob (validation function would be nice, but this will fail fast anyway as the secret wont work if it's missing values)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (await getSecretValueAsJSON("rlsStorage", tenancyName, logger)) as RLSStorageConnectionInfo;
}

export async function getEntityStoreCosmosSecrets(tenancyName: string, logger: Logger): Promise<CosmosSecrets> {
    // justification: parsing json blob (validation function incoming, but this will fail fast anyway as the secret wont work if it's missing values)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (await getSecretValueAsJSON("entityStoreCosmos", tenancyName, logger)) as CosmosSecrets;
}

export async function getSearchStoreCosmosSecrets(tenancyName: string, logger: Logger): Promise<CosmosSecrets> {
    // justification: parsing json blob (validation function incoming, but this will fail fast anyway as the secret wont work if it's missing values)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (await getSecretValueAsJSON("searchStoreCosmos", tenancyName, logger)) as CosmosSecrets;
}

export async function getCognitiveSearchSecrets(tenancyName: string, logger: Logger): Promise<CognitiveSearchSecrets> {
    // justification: parsing json blob (validation function incoming, but this will fail fast anyway as the secret wont work if it's missing values)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (await getSecretValueAsJSON("cognitiveSearch", tenancyName, logger)) as CognitiveSearchSecrets;
}

export async function getServiceBusSecrets(tenancyName: string, logger: Logger): Promise<ServiceBusSecrets> {
    // justification: parsing json blob (validation function incoming, but this will fail fast anyway as the secret wont work if it's missing values)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (await getSecretValueAsJSON("serviceBus", tenancyName, logger)) as ServiceBusSecrets;
}

/**
 * Table storage secrets are the same as blob storage secrets, since accessing either requires the same connection string to
 * the same resource.  This isn't expected to change.
 * @param tenancyName
 * @param logger
 * @returns
 */
export async function getTableStorageSecrets(tenancyName: string, logger: Logger): Promise<TableStorageSecrets> {
    // justification: parsing json blob (validation function incoming, but this will fail fast anyway as the secret wont work if it's missing values)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (await getSecretValueAsJSON("blobStorage", tenancyName, logger)) as TableStorageSecrets;
}

export async function getSharedBlobStorageConnectionString(): Promise<string> {
    const secretString: string = await get(getKeyVaultName(), "shared-blobStorage-connectionString");
    if (!secretString || secretString === "") {
        throw "Value of shared-blobStorage-connectionString secret object is null";
    }
    return secretString;
}

export async function getSharedSendGridAPIKey(): Promise<string> {
    const secretString: string = await get(getKeyVaultName(), "shared-sendgrid-API-key");
    if (!secretString || secretString === "") {
        throw "Value of shared-sendgrid-API-key secret is empty. No emails can be sent.";
    }
    return secretString;
}
export async function getSharedLaunchDarklySdkKey(): Promise<string> {
    const secretString: string = await get(getKeyVaultName(), "shared-launchdarkly-sdk-key");
    if (!secretString || secretString === "") {
        throw "[FeatureFlags] Value of shared-launchdarkly-sdk-key secret is empty. All feature flags will be off.";
    }
    return secretString;
}

export async function getFunctionHostKey(endpoint: Extract<Endpoint, "EntityStoreApi" | "SearchStoreApi" | "SearchApi" | "AdminApi">): Promise<string> {
    const secretName = getSharedFKSecretName(endpoint);

    const secretString: string = await get(getKeyVaultName(), secretName);
    if (!secretString || secretString === "") {
        throw unexpectedError(`Value of host key for ${endpoint} was not found in keyvault (secret name: ${secretName})`, "TenantSecretConnector.getFunctionHostKey");
    }
    return secretString;
}

export async function getFirmFunctionHostKey(endpoint: Extract<Endpoint, "FirmConnectorDataProcessor" | "FirmDataService">, tenancyName: string): Promise<string> {
    const overrideName = EnvironmentService.getDevOverrideFirmPrefix();
    const secretName = getFirmFKSecretName(endpoint, overrideName ?? tenancyName);

    const secretString: string = await get(getKeyVaultName(), secretName);
    if (!secretString || secretString === "") {
        throw unexpectedError(`Value of host key for ${endpoint} was not found in keyvault (secret name: ${secretName})`, "TenantSecretConnector.getFirmFunctionHostKey");
    }
    return secretString;
}

function getSharedFKSecretName(endpoint: Extract<Endpoint, "EntityStoreApi" | "SearchStoreApi" | "SearchApi" | "AdminApi">) {
    switch (endpoint) {
        case "EntityStoreApi": {
            return `shared-entitystore-api-host-default-key`;
        }
        case "SearchStoreApi": {
            return `shared-searchstore-api-host-default-key`;
        }
        case "SearchApi": {
            return `shared-search-api-host-default-key`;
        }
        case "AdminApi": {
            return `shared-admin-api-host-default-key`;
        }
    }
}

function getFirmFKSecretName(endpoint: Extract<Endpoint, "FirmConnectorDataProcessor" | "FirmDataService">, tenancyName: string) {
    switch (endpoint) {
        case "FirmConnectorDataProcessor": {
            return `${tenancyName}-firmconnectordataprocessor-default-key`;
        }
        case "FirmDataService": {
            return `${tenancyName}-firmdataservice-default-key`;
        }
    }
}
