import { NotFound, notFoundWithMessage, ok, Result, unexpectedError } from "aderant-conflicts-models";
import {
    BlobDownloadResponseModel,
    BlobItem,
    BlobServiceClient,
    BlobDeleteResponse,
    BlockBlobParallelUploadOptions,
    BlobUploadCommonResponse,
    ContainerClient,
    BlockBlobUploadOptions,
    BlockBlobUploadResponse
} from "@azure/storage-blob";

export class BlobStorageConnector {
    private client: BlobServiceClient;

    protected constructor(client: BlobServiceClient) {
        this.client = client;
    }

    public static async open(connectionString: string) {
        const client = this.createBlobClient(connectionString);
        return new BlobStorageConnector(client);
    }

    /**
     * Create an intialized BlobConnector.
     *
     * @param connectionString
     * @returns
     */
    protected static createBlobClient(connectionString: string) {
        if (!connectionString) {
            throw unexpectedError("Connection string missing from environment variables", "");
        }
        const client = BlobServiceClient.fromConnectionString(connectionString);
        return client;
    }

    public getAccountName(): string {
        return this.client.accountName;
    }

    /**
     * Check if a blob container exists.
     *
     * @param containerName
     * @returns
     */
    public async doesContainerExist(containerName: string): Promise<boolean> {
        //get a BlobContainerClient
        const container = this.client.getContainerClient(containerName);
        return await container.exists();
    }

    /**
     * Get a blob, throw error if get can't be processed
     * @param containerName
     * @param blobName
     */
    public async getBlob(containerName: string, blobName: string): Promise<Result<BlobDownloadResponseModel, NotFound>> {
        const containerClient = this.client.getContainerClient(containerName);
        const result = containerClient.getBlobClient(blobName);
        if (await result.exists()) {
            const blob = await result.download();
            if (blob._response.status !== 200) {
                throw unexpectedError(JSON.stringify(blob._response), `Get blob ${blobName} from container ${containerName} failed with error code ${blob.errorCode}"(${blob._response.status})"`);
            }
            return blob;
        } else {
            return notFoundWithMessage(`Blob ${blobName} was not found.`);
        }
    }

    /**
     * Uploads data to a blob, throw error if get can't be processed
     * @param containerName
     * @param blobPathName
     * @param data
     * @param options
     */
    public async uploadDataAsBuffer(containerName: string, blobPathName: string, data: Buffer, options?: BlockBlobParallelUploadOptions) {
        const containerClient = await this.getContainer(containerName);
        const result: BlobUploadCommonResponse = await containerClient.getBlockBlobClient(blobPathName).uploadData(data, options);
        if (result._response.status !== 201) {
            throw unexpectedError(JSON.stringify(result), `Update blob failed with error code ${result.errorCode}"(${result._response.status})"`);
        }
        return result;
    }

    private async getContainer(containerName: string): Promise<ContainerClient> {
        const container = this.client.getContainerClient(containerName);
        const schemaContainerExists = await container.exists();
        if (!schemaContainerExists) {
            const createContainerResponse = await this.client.createContainer(container.containerName);
            if (createContainerResponse.containerCreateResponse._response.status !== 201) {
                throw unexpectedError(JSON.stringify(createContainerResponse.containerCreateResponse), `Container "${containerName}" not found. Unexpected error creating container.`);
            }
            return createContainerResponse.containerClient;
        }

        return container;
    }

    /**
     * Get the contents of an existing blob.  Both container and blob must exist already
     * @param containerName
     * @param blobFileName
     */
    public async getBlobContentAsBuffer(containerName: string, blobFileName: string): Promise<Buffer> {
        const containerClient = await this.getContainer(containerName);
        const blockBlobClient = containerClient.getBlockBlobClient(blobFileName);
        const downloadBlockBlobResponse: BlobDownloadResponseModel = await blockBlobClient.download(0);

        if (downloadBlockBlobResponse.errorCode) {
            throw unexpectedError(`Error downloading blob file ${blobFileName} from container ${containerName}: ${downloadBlockBlobResponse.errorCode}`, JSON.stringify(downloadBlockBlobResponse));
        }

        return await this.streamToBuffer(
            downloadBlockBlobResponse.readableStreamBody! //only undefined in browser environments
        );
    }

    /**
     * Get the contents of a blob file as a Buffer and the etag associated with the blob. The container must exist already.
     * If the blob file is not found, returns NotFound
     * @param containerName
     * @param blobFileName
     */
    public async getBlobContentWithEtag(containerName: string, blobFileName: string): Promise<Result<{ content: Buffer; etag: string | undefined }, NotFound>> {
        const blob = await this.getBlob(containerName, blobFileName);
        if (ok(blob)) {
            const blobContentAsBuffer: Buffer = await this.streamToBuffer(
                blob.readableStreamBody! //only undefined in browser environments
            );
            const blobContent = {
                content: blobContentAsBuffer,
                etag: blob._response.parsedHeaders.etag
            };
            return blobContent;
        } else {
            return blob;
        }
    }

    /**
     * Copied from the Azure Blob Client examples.  Reads the contents of a stream into a string buffer and returns that string.  The Blob Client
     * returns streams, this function is to conveniently get the contents.
     *
     * @see https://github.com/Azure/azure-sdk-for-js/blob/main/sdk/storage/storage-blob/samples/typescript/src/basic.ts for source
     * @param readableStream
     * @returns
     */
    public async streamToBuffer(stream: NodeJS.ReadableStream): Promise<Buffer> {
        return new Promise((resolve, reject) => {
            const chunks: Buffer[] = [];
            stream.on("data", (chunk) => chunks.push(Buffer.isBuffer(chunk) ? chunk : Buffer.from(chunk)));
            stream.on("end", () => resolve(Buffer.concat(chunks)));
            stream.on("error", (err) => reject(err));
        });
    }

    /**
     * Retrieve a list of the headers from all files in a blob storage container
     */
    public async getBlobFileHeaders(containerName: string, prefix: string): Promise<BlobItem[]> {
        const containerClient = this.client.getContainerClient(containerName);

        const files = [];
        for await (const blob of containerClient.listBlobsFlat({
            prefix: `${prefix}/` //It'll sort of work without the / but will match too broadly e.g "client" matches "clientalias" too
        })) {
            files.push(blob);
        }
        return files;
    }

    /**
     * Save a blob, throw error if save can't be processed
     * @param containerName - the container into which the blob must be saved
     * @param blobName - the name of the blob to save
     * @param blobContent - a string containing the content to be saved as a blob to the container
     */
    public async saveBlob(containerName: string, blobName: string, blobContent: string, etag?: string): Promise<BlockBlobUploadResponse> {
        // Get a reference to the container
        const containerClient = await this.getContainer(containerName);

        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const options: BlockBlobUploadOptions = {
            blobHTTPHeaders: {
                blobContentType: "json"
            }
        };
        if (etag) {
            options.conditions = {
                ifMatch: etag
            };
        }

        const response = await blockBlobClient.upload(blobContent, blobContent.length, options);
        return response;
    }

    /**
     * Delete a blob, throw error if delete can't be processed
     * @param client
     * @param containerName
     * @param blob
     */
    public async deleteBlob(containerName: string, blob: BlobItem): Promise<BlobDeleteResponse> {
        const containerClient = this.client.getContainerClient(containerName);
        const response = await containerClient.deleteBlob(blob.name);
        if (response._response.status !== 202) {
            throw unexpectedError(`Delete blob failed with error code ${response.errorCode}"(${response._response.status})"`, JSON.stringify(response));
        }
        return response;
    }
}
