import { ValidationErrors } from "aderant-conflicts-models";
import { AzureKeyAuthFunctionDefinition } from "../../AzureFunctionDefinition";
import { OkResponse, EmptyOkResponse } from "../../Http/HttpResponse";
import { TokenAuthContext } from "../../ConflictsContext";
import { getFunctionAppUrl } from "../../Config/EnvironmentService";

export const Surface = {
    keyAuthFunctions: {
        reindexPriorSearches: new AzureKeyAuthFunctionDefinition<{ tenancyName: string }, undefined, EmptyOkResponse, ValidationErrors>({
            httpVerb: "POST",
            expectedErrors: ["VALIDATION"],
            getUrlEnd: () => `./api/KeyAuth/PriorSearch/Reindex`
        }),
        deleteEntityType: new AzureKeyAuthFunctionDefinition<{ entityType: string }, { message: string }, OkResponse<{ message: string }>, ValidationErrors>({
            httpVerb: "POST",
            expectedErrors: ["VALIDATION"],
            getUrlEnd: (input) => `./api/DeleteEntityType/${input.entityType}`
        })
    }
};

export type FirmConnectorDataProcessorProxy = ReturnType<typeof getFirmConnectorDataProcessorApiProxy>;
export function getFirmConnectorDataProcessorApiProxy(context: TokenAuthContext, key: string) {
    const baseUrl: URL = getFunctionAppUrl("FirmConnectorDataProcessor", context.currentUser.tenancy.uniqueName);

    return {
        deleteEntityType: Surface.keyAuthFunctions.deleteEntityType.getProxy(baseUrl, context, key)
    };
}
